import React, { useEffect, useState } from "react";
import Banner from "../assets/images/industry-solution-details-banner.png";
import MobileBanner from "../assets/images/solutionMobile.svg";
import PharmaSolution from "../assets/images/pharma-solutions.png";
import ButtonArrow from "../assets/images/button-arrow.svg";
import VideoSection from "../assets/images/video-section.png";
import USP1 from "../assets/images/usp-1.png";
import USP2 from "../assets/images/usp-2.png";
import USP3 from "../assets/images/usp-3.png";
import CaseStudies from "./CaseStudies";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ProductsBannerListing from "../assets/images/ProductBanner.png";
import Slider from "react-slick";
import Next from "../assets/images/nextArrow.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./customDots.css";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { SwiperSlide } from "swiper/react";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "white",
        zIndex: 99,
        height: 64,
        width: 64,
        borderRadius: 100,
        borderColor: " rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        top: "50%",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)", // Added box shadow
        marginRight: "30px",
      }}
      onClick={onClick}
    >
      <img
        style={{
          position: "absolute",
          top: 21,
          left: 26,
        }}
        src={Next}
      />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "white",
        zIndex: 99,
        height: 64,
        width: 64,
        borderRadius: 100,
        // left: -25,
        marginLeft: "30px",
        top: "50%",
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)", // Added box shadow
      }}
      onClick={onClick}
    >
      <img
        style={{
          position: "absolute",
          top: 21,
          left: 24,
        }}
        className="rotate-180 "
        src={Next}
      />
    </div>
  );
};
const IndustrySolutionDetails = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [screenWidth, setScreenWidth] = useState(0);
  const [cards, setCards] = useState();
  const [activeSlide, setActiveSlide] = useState(0);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const queryParams = new URLSearchParams(location.search);

  const solutionId = queryParams.get("solutionId");
  console.log("Solution ID:", solutionId);
  const settings = {
    dots: false,
    customPaging: (i) => (
      <div className="custom-dot">
        <div className={`dot ${i === activeSlide ? "active" : ""}`}></div>
      </div>
    ),
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    centerMode: true,
    nextArrow: isMobile ? null : <NextArrow />,
    prevArrow: isMobile ? null : <PrevArrow />,
    afterChange: (current) => setActiveSlide(current),
    responsive: [
      {
        breakpoint: 768, // Mobile devices
        settings: {
          dots: true,
          customPaging: (i) => (
            <div className="custom-dot">
              <div className={`dot ${i === activeSlide ? "active" : ""}`}></div>
            </div>
          ),
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: false,
          centerPadding: "20px",
          centerMode: true,
          nextArrow: null,
          prevArrow: null,
        },
      },
      {
        breakpoint: 1024, // Tablets
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 2,
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 1240, // Tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "10px",
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  React.useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getData();
  }, [solutionId]);
  const BaseUrl = "https://ukladmin.3mindsdigital.com";
  const getData = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/api/solution-details?filters[solutionId][$eq]=${solutionId}&populate[IndustryBanner]=*&populate[mainBanner]=*&populate[innovatorBanner]=*&populate[mobileMainBanner]=*&populate[USP][populate]=*`
      );
      setCards(response.data.data);
      console.log("cards", response.data.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => setIsLoading(false), 2000); // Adjust delay as needed
    return () => clearTimeout(timer);
  }, []);

  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ px: { xs: 2, md: 0 } }}>
      <Helmet>
        <title>Industry Solutions Details</title>
        <link rel="canonical" href="https://uniklinger.com" />
      </Helmet>
      <div className="container mt-[100px]">
        <div className="relative">
          <img
            src={
              screenWidth > 768
                ? BaseUrl.concat(
                    cards?.[0]?.attributes?.mainBanner?.data?.attributes?.url
                  )
                : BaseUrl.concat(
                    cards?.[0]?.attributes?.mobileMainBanner?.data?.attributes
                      ?.url
                  )
            }
            alt="banner"
            className="banner-image"
            itemScope
            itemType="https://schema.org/ImageObject"
            itemProp="image"
          />

          <div
            className="absolute product-banner-title text-white top-[30%] md:top-[25%] left-[5%]"
            itemProp="name"
          >
            {/* {cards?.[0]?.attributes?.title} */}
          </div>
          <div
            className="font-16 text-white absolute poppins  text-left top-[60%] md:top-[50%] left-[5%] w-[100%] md:w-[50%] "
            itemProp="description"
          >
            {/* {cards?.[0]?.attributes?.titleDescription} */}
          </div>
        </div>
        <div className="pt-50 flex details-container">
          <div className="details-image-container w-2/5">
            <img
              src={BaseUrl.concat(
                cards?.[0]?.attributes?.innovatorBanner?.data?.attributes?.url
              )}
              alt="pharma-solution"
              className="w-full h-full"
              itemScope
              itemType="https://schema.org/ImageObject"
              itemProp="image"
            />
          </div>
          <div
            className="details-content-container w-3/5 ml-[40px] mr-[40px]"
            itemScope
            itemType="https://schema.org/Article"
          >
            <div className="details-title pb-20" itemProp="headline">
              {cards?.[0]?.attributes?.title} Innovators
            </div>
            <div className="details-description" itemProp="articleBody">
              {cards?.[0]?.attributes?.description}
            </div>
            <div className="pt-20">
              <button
                className="button-class flex items-center justify-center"
                itemProp="potentialAction"
                itemScope
                itemType="https://schema.org/Action"
              >
                <span className="pr-2" itemProp="name">
                  Enquire Now
                </span>
                <span>
                  <img src={ButtonArrow} alt="arrow" className="ml-2" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="pt-50 flex">
          <div className="relative mobileHide">
            <img
              src={BaseUrl.concat(
                cards?.[0]?.attributes?.IndustryBanner?.data?.attributes?.url
              )}
              alt="video-section"
              className="w-full"
              itemScope
              itemType="https://schema.org/VideoObject"
              itemProp="video"
            />
            <div
              style={{
                backgroundColor: "#00000061",
                width: "25%",
                height: "auto",
                padding: "15px",
              }}
              className="absolute text-white top-[40%]  left-[5%] poppins  text-[14px]"
              itemProp="name"
            >
              <div className="poppins text-[24px] font-bold">
                Process Of Industry
              </div>
              {cards?.[0]?.attributes?.processOfInddustry}
            </div>
          </div>
        </div>
        <div
          style={{
            paddingTop: isMobile ? "20px" : "50px",
            paddingBottom: isMobile ? 0 : "30px",
            marginBottom: isMobile ? "20px" : 0,
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            sx={{ fontSize: { xs: "20px", md: "35px" }, marginBottom: 3 }}
          >
            <span style={{ fontWeight: "bold", paddingRight: 2 }}>
              Our USP’s In
            </span>
            <span style={{ fontFamily: "poppins", fontWeight: 400 }}>
              {" "}
              {cards?.[0]?.attributes?.title}
            </span>
          </Typography>
          {cards?.[0]?.attributes?.USP?.length > 0 ? (
            <Slider {...settings} style={{paddingBottom: isMobile?"100px":"160px", overflow: isMobile? "visbile":"hidden"}}>
              {cards?.[0]?.attributes?.USP?.map((usp, index) => (
                <SwiperSlide key={index} >
                  <Card
                    sx={{
                      width: isMobile ? "90%" : 358,
                      height: isMobile ? "100%" : 380,
                      margin: "auto",
                    }}
                  >
                    <CardActionArea sx={{display: "flex", flexDirection: "column"}}>
                      <CardMedia
                        component="img"
                        sx={{
                          objectFit: isMobile? "fill": "cover",
                          height: isMobile ? "100%" : 230,
                        }}
                        image={BaseUrl.concat(
                          usp?.image1?.data?.[0]?.attributes?.url
                        )}
                        alt={`USP-${index + 1}`}
                      />
                      <CardContent
                        sx={{
                          height: "calc(100% - 320px)",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                          padding: isMobile? 1 : 3,
                        }}
                      >
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {usp?.title1}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            color: "#2A2A2A",
                            marginTop: 1,
                          }}
                        >
                          {usp?.description1}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </SwiperSlide>
              ))}
            </Slider>
          ) : (
            <div className="grid grid-row-3 md:grid-cols-3 ">
              {cards?.[0]?.attributes?.USP?.map((usp, index) => (
                <SwiperSlide key={index} style={{padding: isMobile? "10px": 0}}>
                  <Card
                    sx={{
                      width: isMobile ? "100%" : 398,
                      height: isMobile ? "100%" : 380,
                      margin: isMobile?"10px" :"auto",
                    }}
                  >
                    <CardActionArea sx={{display: "flex", flexDirection: "column"}}>
                      <CardMedia
                        component="img"
                        sx={{
                          objectFit: "cover",
                          height: isMobile ? "100%" : 230,
                        }}
                        image={BaseUrl.concat(
                          usp?.image1?.data?.[0]?.attributes?.url
                        )}
                        alt={`USP-${index + 1}`}
                      />
                      <CardContent
                        sx={{
                          height: "calc(100% - 240px)",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                          padding: 3,
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {usp?.title1}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            color: "#2A2A2A",
                            marginTop: 1,
                          }}
                        >
                          {usp?.description1}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </SwiperSlide>
              ))}
            </div>
          )}
        </div>
      </div>
    </Box>
  );
};

export default IndustrySolutionDetails;
