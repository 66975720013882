import React from "react";

import Pharmaceuticals from "../assets/images/pharmaceuticals.jpg";
import "./IndustrySec.css";
import Tyre from "../assets/images/tyre.jpg";
import Textile from "../assets/images/textile.jpg";
import ChemicalPetrochemical from "../assets/images/chemical-petrochemical.jpg";
import Rubber from "../assets/images/rubberIndustry.png";
import Brewery from "../assets/images/brewery.png";
import AeratedConcreteBlock from "../assets/images/aerated.png";
import ButtonArrow from "../assets/images/button-arrow.svg";

const IndustrySec = (props) => {
  console.log("props", props.industry);
  let data = {};
  if (props.industry === "rubber") {
    data.image = Rubber;
    data.description =
      "Compression moulding is the most common moulding technique used in the rubber industry. It involves pressing uncured rubber between heated moulds so that the rubber compound first fills the mould cavity before curing to produce finished article. The presses are usually pneumatically or hydraulically powered and the mould can be heated by steam. UKL delivers steam valves and other accessories used in the rubber industry.";
    data.title = "Rubber Industry";
    data.redirect_url = "/industry-solution-details?solutionId=20";
    // "http://localhost:3000/industry-solution-details?solutionId=20";
  }
  if (props.industry === "brewery") {
    data.image = Brewery;
    data.description =
      "Uni Klinger delivers innovative Steam Engineering & Sealing technologies and solutions for all stages of Brewery and Distillation processing, including copper vessel, fermenter, pasteurizer, Bottling & canning, Boiler and more while also taking into account the need for regulatory compliance, reduced downtime, and ease of maintenance.";
    data.title = "Brewery Industry";
    data.redirect_url = "/industry-solution-details?solutionId=17";
  }
  if (props.industry === "textiles") {
    data.image = Textile;
    data.description =
      "Optimize textile manufacturing with our efficient steam and condensate management solutions, ensuring smooth and energy-efficient operations";
    data.title = "Textile";
    data.redirect_url = "/industry-solution-details?solutionId=14";
  }
  if (props.industry === "tyre-manufacturing") {
    data.image = Tyre;
    data.description =
      "Enhance tyre production with our leak-proof valves and effective steam traps, designed to withstand the high temperatures and pressures of the manufacturing process";
    data.title = "Tyre Manufacturing";
    data.redirect_url = "/industry-solution-details?solutionId=15";
  }
  if (props.industry === "aerated-concrete-block") {
    data.image = AeratedConcreteBlock;
    data.description =
      "Uni Klinger's products help maintain consistent quality in block production, minimize operational disruptions, and extend the lifespan of equipment.";
    data.title = "Aerated Block Industry";
    data.redirect_url = "/industry-solution-details?solutionId=16";
  }
  if (props.industry === "chemical-petrochemical") {
    data.image = ChemicalPetrochemical;
    data.description =
      "Ensure zero leakage and optimal process control with our robust valves, gaskets, and condensate management solutions, tailored for chemical and petrochemical applications";
    data.title = "Chemical & Petrochemical Plants";
    data.redirect_url = "/industry-solution-details?solutionId=4";
  }
  if (props.industry === "pharmaceutical") {
    data.image = Pharmaceuticals;
    data.description =
      "Meet stringent pharmaceutical standards with our high-performance valves, gaskets, and steam traps, ideal for applications involving pure steam and critical media";
    data.title = "Pharmaceuticals";
    data.redirect_url = "/industry-solution-details?solutionId=1";
  }
  const handleRedirection = (url) => {
    window.location.href = url;
  };
  return (
    <div className="flex w-[100%] mb-[100px]">
      <div
        className=" flex flex-col justify-start"
        style={{ height: "600px", maxHeight: "600px" }}
      >
        <h1 className="flex justify-start text-6xl poppins">
          <span className="font-semibold poppins mr-[8px]">Solutions</span>{" "}
          Industry Wise
        </h1>
        <div>
          <div>
            <div className="border relative  border-[#CFCCC4] p-4">
              <img
                src={data.image}
                style={{
                  width: "100%", // Make image responsive, filling the container width
                  height: "300px", // Fixed height for all images
                  objectFit: "cover", // Ensures images maintain their aspect ratio and cover the area
                }}
                alt="industry images"
              />

              <div className="absolute bottom-[30px] left-[40px]">
                {/* <div className="flex items-center justify-center w-[16rem] bg-black bg-opacity-45 border-1.5 border-gray-400 rounded-full">
                  <div className="font-normal p-4 pl-[3rem] flex w-[14rem] flex justify-center items-center color-white">
                    Most Reliable
                </div>
</div> */}
                <div className="carousel-solution-title">{data.title}</div>
              </div>
            </div>

            <div>
              <div className="carousel-solution-description">
                {data.description}
              </div>
            </div>
            <div>
              <button
                onClick={() => handleRedirection(data?.redirect_url)}
                className="card-button-class d-flex items-baseline "
                style={{ marginTop: "20px", padding: "18px 40px 18px 40px" }}
              >
                <span className="discoverNow mr-5">Discover Now</span>
                <span>
                  <img src={ButtonArrow} alt="arrow" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-[50%]">
        <div></div>
        <div></div>
      </div> */}
    </div>
  );
};

export default IndustrySec;
