import React, { useEffect, useState } from "react";
import FancyCarousel from "react-fancy-circular-carousel";
import IndustrySec from "./IndustrySec";

import ChemicalPetrochemical from "../assets/images/ChemicalIndustry.svg";
import Pharmaceutical from "../assets/images/PharmaIndustry.svg";
import Rubber from "../assets/images/RubberIndustry.svg";
import Soap from "../assets/images/SoapIndustry.svg";
 import Brewery from "../assets/images/BreweryIndustry.svg";
import AeratedConcreteBlock from "../assets/images/AeratedConcreteBlock.svg";
import Textiles from "../assets/images/TextileIndustry.svg";
import TyreManufacturing from "../assets/images/TyreIndustry.svg";


const SolutionsCarousel = () => {
  const images = [
    Rubber,
    Brewery,
    Textiles,
    TyreManufacturing,
    AeratedConcreteBlock,
    ChemicalPetrochemical,
    Pharmaceutical,

  
  ];
  const info = [
    <IndustrySec industry="rubber" />,
    <IndustrySec industry="brewery" />,
    <IndustrySec industry="textiles" />,
    <IndustrySec industry="tyre-manufacturing" />,
    <IndustrySec industry="aerated-concrete-block" />,
    <IndustrySec industry="chemical-petrochemical" />,
    <IndustrySec industry="pharmaceutical" />,
    
   
   
  ];
  const [focusElement, setFocusElement] = useState(0);

  useEffect(() => {
    console.log(focusElement);
    let buttons = document.querySelectorAll(
      ".fancy-carousel-navigation-button"
    );
    buttons.forEach((button, index) => {
      if (index === 1) {
        console.log(button);
        button.innerHTML = `<i class="fas fa-chevron-down"></i>`;
      }
      if (index === 0) {
        button.innerHTML = `<i class="fas fa-chevron-down"></i>`;
      }
    });
    let carouselElements = document.querySelectorAll(".fancy-carousel-element");
    carouselElements.forEach((element, index) => {
      console.log(element.children[0].style);
      if (index === focusElement) {
        element.children[0].style.filter = "none";
      } else {
        element.children[0].style.filter = "grayscale(100%)";
      }
      //   if(index === focusElement){
      //     element.style.width='120px';
      //     element.style.height='120px';
      //   }
      //    if(index === focusElement -1){
      //     element.style.width='80px';
      //     element.style.height='80px';
      //   }
      //    if(index === focusElement + 1){
      //     element.style.width='80px';
      //     element.style.height='80px';
      //   }
      //   if(index === focusElement -2){
      //     element.style.width='40px';
      //     element.style.height='40px';
      //   }
      //    if(index === focusElement + 1){
      //     element.style.width='40px';
      //     element.style.height='40px';
      //   }
    });
  }, [focusElement]);
  const [screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
    // Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setScreenWidth(entry.contentRect.width);
      }
    });

    // Start observing the selected element
    resizeObserver.observe(document.body);
  }, [screenWidth]);
  return (
    <div
      style={screenWidth < 820 ? { display: "none" } : { position: "relative"}}
      className="container md:mt-[50px] md:mb-[50px] lg:mb-[50px]  "
    >
      {/* <div
        className="carousel custom-rotating-carousel"
        style={{ transform: "rotate(-90deg)" }}
      >
        <FancyCarousel
          images={images}
          carouselRadius={200}
          peripheralImageRadius={60}
          centralImageRadius={0}
          setFocusElement={setFocusElement}
          focusElementStyling={{}}
          borderWidth={0}
          borderHexColor={"808080"}
        />
        <div className="info-box-wrapper">
          <div class="info-container">
            <p> {info[focusElement]} </p>
          </div>
        </div>
      </div> */}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20rem",
        
      

        }}
        className="carousel-section  col-md-12 "
      >
        <div  style={{ order: 2,display:'flex',justifyContent:'center',marginTop:'-100px' }} className="col-md-6">
        <FancyCarousel
          images={images}
          carouselRadius={200}
          peripheralImageRadius={60}
          centralImageRadius={0}
          setFocusElement={setFocusElement}
          focusElementStyling={{}}
          borderWidth={0}
          borderHexColor={"808080"}
        />
        </div>
        
        <div style={{ order: 1 }} className="info-box-wrapper col-md-6">
        <div >
            <p> {info[focusElement]} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsCarousel;
