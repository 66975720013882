import React, { useState, useEffect } from "react";
import Pharmaceuticals from "../assets/images/pharmaceuticals.jpg";
import Tyre from "../assets/images/tyre.jpg";
import Paper from "../assets/images/paper.jpg";
import Textile from "../assets/images/textile.jpg";
import FoodBeverages from "../assets/images/food-beverages.jpg";
import ChemicalPetrochemical from "../assets/images/chemical-petrochemical.jpg";
import OilRefinery from "../assets/images/oil-refinery.jpg";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

// import Pharmaceuticals from "../assets/images/pharmaceuticals.jpg";
// import Tyre from "../assets/images/tyre.jpg";
// import Textile from "../assets/images/textile.jpg";
// import ChemicalPetrochemical from "../assets/images/chemical-petrochemical.jpg";
import Rubber from "../assets/images/rubberIndustry.png";
import Brewery from "../assets/images/brewery.png";
import AeratedConcreteBlock from "../assets/images/aerated.png";

const MobileIndustrySolution = () => {
  const [industry, setIndustry] = useState("rubber");
  const [data, setData] = useState({});

  useEffect(() => {
    const industryData = {
      "rubber": {
        image: Rubber,
        description: "Compression moulding is the most common moulding technique used in the rubber industry. It involves pressing uncured rubber between heated moulds so that the rubber compound first fills the mould cavity before curing to produce finished article. The presses are usually pneumatically or hydraulically powered and the mould can be heated by steam. UKL delivers steam valves and other accessories used in the rubber industry.",
        title: "Rubber Industry",
      },
      "brewery": {
        image: Brewery,
        description:  "Uni Klinger delivers innovative Steam Engineering & Sealing technologies and solutions for all stages of Brewery and Distillation processing, including copper vessel, fermenter, pasteurizer, Bottling & canning, Boiler and more while also taking into account the need for regulatory compliance, reduced downtime, and ease of maintenance.",
        title: "Brewery Industry",
      },
      "textiles": {
        image: Textile,
        description: "Optimize textile manufacturing with our efficient steam and condensate management solutions, ensuring smooth and energy-efficient operations",
        title: "Textile",
      },
      "tyre-manufacturing": {
        image: Tyre,
        description: "Enhance tyre production with our leak-proof valves and effective steam traps, designed to withstand the high temperatures and pressures of the manufacturing process",
        title: "Tyre Manufacturing",
      },
      "aerated-concrete-block": {
        image: AeratedConcreteBlock,
        description: "Uni Klinger's products help maintain consistent quality in block production, minimize operational disruptions, and extend the lifespan of equipment.",
        title: "Aerated Block Industry",
      },
      "chemical-petrochemical": {
        image: ChemicalPetrochemical,
        description: "Ensure zero leakage and optimal process control with our robust valves, gaskets, and condensate management solutions, tailored for chemical and petrochemical applications",
        title: "Chemical & Petrochemical Plants",
      },
      "pharmaceutical": {
        image: Pharmaceuticals,
        description: "Meet stringent pharmaceutical standards with our high-performance valves, gaskets, and steam traps, ideal for applications involving pure steam and critical media",
        title: "Pharmaceuticals",
      },
    };

    setData(industryData[industry]);
  }, [industry]);

  function handleChange(event) {
    setIndustry(event.target.value);
  }

  return (
    <div itemScope itemType="https://schema.org/WebPage" className="fluid-container mt-[100px]">
      <div className="industry-select-container" itemScope itemType="https://schema.org/Thing">
      <div className="solution-title" itemProp="headline">
        <b>Solutions</b> Industry Wise
      </div>
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label" itemProp="name">Solution Industry</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={industry}
           
            onChange={handleChange}
          >
            <MenuItem sx={{fontSize:16}}  value="rubber" itemProp="additionalType">Rubber Industry</MenuItem>
            <MenuItem sx={{fontSize:16}}  value="brewery" itemProp="additionalType">Brewery Industry</MenuItem>
            <MenuItem sx={{fontSize:16}}  value="textiles" itemProp="additionalType">Textiles</MenuItem>
            <MenuItem sx={{fontSize:16}}  value="tyre-manufacturing" itemProp="additionalType">Tyres Manufacturing</MenuItem>
            <MenuItem sx={{fontSize:16}} value="aerated-concrete-block" itemProp="additionalType">Aerated Block Industry</MenuItem>
            <MenuItem sx={{fontSize:16}}  value="chemical-petrochemical" itemProp="additionalType">Chemical & Petrochemical Plants</MenuItem>
            <MenuItem sx={{fontSize:16}}  value="pharmaceutical" itemProp="additionalType">Pharmaceuticals</MenuItem>
          </Select>
        </FormControl>
      </div>
      {/* <div className="solution-title" itemProp="headline">
        <b>Solutions</b> Industry Wise
      </div> */}
      <div className="solution-image-container" itemScope itemType="https://schema.org/ImageObject" style={{paddingTop: "20px"}}>
        <img
          src={data.image}
          alt={data.title}
          description={data.description}
          className="solution-image"
          itemProp="contentUrl"
        />
        <div className="solution-relative-text">
          <div className="solution-image-subtitle" itemProp="caption">Most Reliable</div>
          <div className="solution-main-image-title" itemProp="name">
            {data.title}
          </div>
        </div>
      </div>
      <div className="solution-description" itemProp="description">
        {data.description}
      </div>
    </div>
  );
  
};

export default MobileIndustrySolution;
